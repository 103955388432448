const ServicesData = [
    {
        name: "Frontend Development",
        description: "I create beautiful, secure, and dynamic web applications. In addition, I create stunning and easy to use web applications"
    },
    {
        name: "Backend Development",
        description: "In addition, I also create secure and performant backend applications using industry standard technologies."
    },
    {
        name: "Mobile Development",
        description: "Studies show that 70% of internet traffic comes from mobile phones. I also creat user friendly mobile applications."
    },
]

export default ServicesData